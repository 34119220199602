import React from 'react'
import './Contact.css'

export default function Contact() {
  return (
    <div className='contact'>
        <div>Jahimecameau@gmail.com</div>
        <div>(978)-902-3889</div>
        <div>Methuen, Massachusetts</div>
    </div>
  )
}
